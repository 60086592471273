import React, { useEffect, useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap"
import SelectBox from "../../../SelectBox/SelectBox"

import {AllDepartmentsInsights, NewsAndInsight, AllNewsAndInsightsCategories} from "../../../../queries/common_use_query"

import { useContainerContext } from "../../../../store/ContainerContext";

const ServiceFilter = (props) => {
    const category_name = props.category_name;
    const context = useContainerContext();
    const {filter_by_serv} = context.state;
    const[filter_data, setFilterData] = useState([]);
    const { loading, error, data } = category_name ? NewsAndInsight(category_name) : AllNewsAndInsightsCategories();
    useEffect(()=>{
        var uniqueItem = [];
        var uniqueOptions = [];
        var default_option = { value: 'all', label: "All"}
        data && data.newsAndInsights.length > 0 && data.newsAndInsights.map((item, i) => {
            if(item?.insight_departments?.[0]?.Name){
                var trim_item = item?.insight_departments?.[0]?.Name.trim();
                if (uniqueItem.indexOf(trim_item) === -1) {
                    uniqueItem.push(trim_item);
                    uniqueOptions.push({ value: trim_item, label: trim_item});
                }                
            }
        })
        console.log("Res uniqueOptions",uniqueOptions)
        uniqueOptions.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        setFilterData([default_option, ...uniqueOptions]);
    }, [data, filter_by_serv]);
    
    // useEffect(()=>{
    //     var uniqueItem = [];
    //     var uniqueOptions = [];
    //     var default_option = { value: '', label: "Recent Experience"};

    //     props.data && props.data.length > 0 && props.data.map((item, i) => {    
    //         if(item.Service){  
    //             var trim_item = item.Service.trim();          
    //             if (uniqueItem.indexOf(trim_item) === -1) {               
    //                 uniqueItem.push(trim_item);

    //                 uniqueOptions.push({ value: trim_item, label: trim_item});
    //             }                
    //         }
    //     })
    //     uniqueOptions.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    //     setFilterData([default_option, ...uniqueOptions]);
    // }, [props.data])

    return (
        <React.Fragment>
            {                
                filter_data.length > 1 && 
                <div className="category-select services">
                    <SelectBox
                        placeHolder="Recent Experience"
                        options={filter_data}
                        value={props.filter_by_val ? props.filter_by_val : ""}
                        handleChange={(e) => {props.FilterByDispatchSer(e.value)}}
                    />
                </div>
            }
            
        </React.Fragment >
    );
};
export default ServiceFilter;
