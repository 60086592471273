import React, { useEffect, useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap"
import SelectBox from "../../../SelectBox/SelectBox"


import { useContainerContext } from "../../../../store/ContainerContext";

const CategoryFilter = (props) => {
    const context = useContainerContext();
    const {filter_by_cat} = context.state;

    const[filter_data, setFilterData] = useState([]);
    
    useEffect(()=>{
        var uniqueItem = [];
        var uniqueOptions = [];
        var default_option = { value: '', label: "All"} //{ value: 'all', label: "Insights"};

        props.data && props.data.length > 0 && props.data.map((item, i) => {            
            if(item.news_and_insights_category.Name && item.news_and_insights_category.Name !== "all"){
                if (uniqueItem.indexOf(item.news_and_insights_category.Name) === -1) {               
                    uniqueItem.push(item.news_and_insights_category.Name);

                    uniqueOptions.push({ value: item.news_and_insights_category.Name, label: item.news_and_insights_category.Name});
                }                
            }
        })
        uniqueOptions.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

        if(filter_by_cat === "all"){
            //setFilterData([{ value: 'all', label: "Insights"}, default_option, ...uniqueOptions]);
        } else{
            setFilterData([default_option, ...uniqueOptions]);
        }
    }, [props.data, filter_by_cat])

    //console.log("filter_by_cat", props.filter_by_val);

    return (
        <React.Fragment>
            {
                filter_data.length > 1 && 
                <div className="category-select category" >
                    <SelectBox
                        placeHolder="All"
                        options={filter_data}
                        value={props.filter_by_val ? props.filter_by_val : ""}
                        handleChange={(e) => {props.FilterByDispatch(e.value);props.handleClikCat(e.value);props.FilterByDispatchSer("all")}}
                    />
                </div>
            }
            
        </React.Fragment >
    );
};
export default CategoryFilter;
